import valid from "card-validator"; //import statement

import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../../components/CustomSelect";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";

import {
  ListGroup,
  Container,
  Row,
  Button,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import { useFormik } from "formik";
import { FiCheck } from "react-icons/fi";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import logoIcon from "../../public/images/logo.svg";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import Image from "../../components/Image";
import Service from "../../http";
import SvgLogo from "../../Pages/frontend/SvgLogo";
import MobileNumber from "../../components/MobileNumber";
import { getValue } from "@testing-library/user-event/dist/utils";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const countryList = [{ label: "Select Country", value: "" }];
const stateList = [{ label: "Select State", value: "" }];
const cityList = [{ label: "Select City", value: "" }];

export default function AcademyRegister({ header }) {
  const statex = useSelector((state) => state);
  const [isLoading, setIsLoader] = useState(false);
  const [sport, setSport] = useState([{ label: "Select Sport", value: null }]);
  const [countryShortname, setCountryShortname] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [showConfPwd, setShowConfPwd] = useState(false);
  const [currency, setCurrency] = useState([
    { label: "Select Currency", value: null },
  ]);
  const [language, setLanguage] = useState([
    { label: "Select Language", value: null },
  ]);
  const [domainCheck, setDomainCheck] = useState();
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState([]);
  const [togglePaymentForm, setTogglePaymentForm] = useState("code");
  const [formStep, setFormStep] = useState(1);
  const stepCount = 3;
  const services = new Service();

  function notify(message) {
    toast(message);
  }

  function isValidCardNo(cardNo) {
    let even = false;
    return (
      cardNo
        .split("")
        .reverse()
        .map(Number)
        .reduce(
          (sum, d) =>
            (sum += (even = !even) ? d : d < 5 ? d * 2 : (d - 5) * 2 + 1),
          0
        ) %
        10 ===
      0
    );
  }

  const validationScema = Yup.object().shape({
    academy_name: Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    family_name: Yup.string().required("Required"),
    first_name_other: Yup.string().required("Required"),
    family_name_other: Yup.string().required("Required"),
    email: Yup.string().email("Not a proper email"),
    gender: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
    mobile: Yup.string().required("Required"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .oneOf([Yup.ref("password")], "Passwords does not match"),
    address: Yup.string().required("Required"),
    country_id: Yup.mixed().required("Required"),
    state_id: Yup.mixed().required("Required"),
    city_id: Yup.mixed().required("Required"),

    domain_name: Yup.string().required("Required"),
    training_setup: Yup.string().required("Required"),
    enter_other: Yup.string().required("Required"),
    section_to_disposal: Yup.string().required("Required"),
    number_of_players: Yup.string().required("Required"),
    approx_number: Yup.string().required("Required"),
    bypass_type: Yup.string().notRequired(),
    bypass_code: Yup.string().required("Required"),

    academy_card: Yup.object().shape({
      card_holder: Yup.string().required("Required"),
      card_number: Yup.string()
        .test(
          "test-number", // this is used internally by yup
          "Credit Card number is invalid", //validation message
          (value) => valid.number(value).isValid
        ) // return true false based on validation
        .required(),
      exp_date: Yup.string().required("Required"),
      cvv: Yup.string().required("Required"),
    }),
    academy_sports: Yup.mixed().required("Required"),
    language_id: Yup.mixed().required("Required"),
    currency_id: Yup.mixed().required("Required"),
  });

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      academy_name: "",
      first_name: "",
      first_name_other: "",
      family_name: "",
      family_name_other: "",
      email: "",
      gender: "",
      dob: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      address: "",
      country_id: 0,
      state_id: 0,
      city_id: 0,
      domain_name: "",
      training_setup: "",
      enter_other: "",
      section_to_disposal: "",
      number_of_players: "",
      approx_number: "",
      bypass_type: "card",
      bypass_code: "",
      // "bypass_type": "code",
      // "bypass_code": "academy@academy",
      academy_card: {
        card_holder: "",
        card_number: "",
        cvv: "",
        exp_date: "",
      },
      academy_sports: [],
      language_id: 1,
      currency_id: 1,
    },
    onSubmit: (values, { resetForm }) => {
      resetForm();
    },
    validationSchema: validationScema,
  });

  React.useEffect(() => {
    services &&
      services
        .get("academy/check-domain?sub_domain=" + formik.values.domain_name)
        .then((res) => {
          if (res.status === true) {
            setDomainCheck(res.message);
          } else {
            setDomainCheck(res.message[0].message);
          }
        });
  }, [formik.values.domain_name]);

  const submitForm = () => {
    setIsLoader(true);
    services &&
      services.post("academy/register", formik.values).then((res) => {
        if (res.status) {
          // window.location.href = 'https://hsf.hitcourt.com/academy-login'
          notify(res.message);
          // document.querySelector('form').reset()
        } else {
          console.log(res.message);
          notify(res.message[0].message);
          notify(res.message);
        }
        setIsLoader(false);
      });
  };

  React.useEffect(() => {
    services &&
      services.get("country").then((res) => {
        // countryList
        res.data.map((i) => {
          const singleCountry = {};
          singleCountry.label = i.name;
          singleCountry.value = i.id;
          singleCountry.shortname = i.shortname;
          countryList.push(singleCountry);
        });
        setCountry(countryList);
      });
  }, []);

  React.useEffect(() => {
    stateList.splice(0, stateList.length);
    services &&
      services.get("states").then((res) => {
        res.data.map((i) => {
          if (i.country_id === formik.values.country_id) {
            const singleState = {};
            singleState.label = i.name;
            singleState.value = i.id;
            stateList.push(singleState);

            setState(stateList);
          }
        });
      });
  }, [formik.values.country_id]);

  React.useEffect(() => {
    cityList.splice(0, cityList.length);
    services &&
      services.get("cities").then((res) => {
        res.data.map((i) => {
          if (i.state_id === formik.values.state_id) {
            const singleCity = {};
            singleCity.label = i.name;
            singleCity.value = i.id;
            cityList.push(singleCity);

            setCities(cityList);
          }
        });
      });
  }, [formik.values.state_id]);

  React.useEffect(() => {
    services &&
      services.get("sports/listview").then((res) => {
        setSport([]);
        res.data.content.map((i) => {
          let singleSport = {};
          singleSport.label = i.name_en;
          singleSport.value = i.id;
          setSport((old) => [...old, singleSport]);
        });
      });
    services &&
      services.get("currency").then((res) => {
        setCurrency([]);
        res.data.map((i) => {
          let singleCurrency = {};
          singleCurrency.label = i.currency_name;
          singleCurrency.value = i.id;
          setCurrency((old) => [...old, singleCurrency]);
        });
      });
    services &&
      services.get("language").then((res) => {
        setLanguage([]);
        res.data.map((i) => {
          let singleLanguage = {};
          singleLanguage.label = i.language_name + " (" + i.language_code + ")";
          singleLanguage.value = i.id;
          setLanguage((old) => [...old, singleLanguage]);
        });
      });
  }, []);

  const onPhoneNumberChange = (b, c) => {
    console.log(c.dialCode + "" + b);
  };

  const cardinfo = {};

  return (
    <Container fluid className="auth">
      <ToastContainer />
      <style jsx>{`
        .footer,
        .navbar {
          display: none;
        }
      `}</style>
      <Row className="overflow-auto vh-100">
        <Col lg={6} className="py-lg-5 py-3 px-lg-5 px-3">
          <div className="text-center py-md-5 py-3">
            <Link to="/">
              <SvgLogo
                className="justify-content-center"
                color1={"#FF5C00"}
                color2={"#000"}
                height={80}
              />
              <h4 className="text-dark mt-4">Register Your Academy</h4>
            </Link>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            {formStep === 1 ? (
              <Row className="justify-content-center">
                <Col className="col-md-5 col-12 mb-3">
                  <label>First Name (En)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.first_name ? (
                    <div className="error">{formik.errors.first_name}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Family Name (En)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Family Name"
                    name="family_name"
                    value={formik.values.family_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.family_name ? (
                    <div className="error">{formik.errors.family_name}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>First Name (Local)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="first_name_other"
                    value={formik.values.first_name_other}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.first_name_other ? (
                    <div className="error">
                      {formik.errors.first_name_other}
                    </div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Family Name (Local)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Family Name"
                    name="family_name_other"
                    value={formik.values.family_name_other}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.family_name_other ? (
                    <div className="error">
                      {formik.errors.family_name_other}
                    </div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Academy Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Academy Name"
                    name="academy_name"
                    value={formik.values.academy_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.academy_name ? (
                    <div className="error">{formik.errors.academy_name}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 mb-lg-4 col-12   mb-3">
                  <label>Select Gender</label>
                  <CustomSelect
                    value={formik.values.gender}
                    onChange={(value) =>
                      formik.setFieldValue("gender", value.value)
                    }
                    options={[
                      { label: "Select gender", value: "" },
                      { value: "M", label: "Male" },
                      { value: "F", label: "Female" },
                    ]}
                  />
                  {formik.errors.gender ? (
                    <div className="error">{formik.errors.gender}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3 position-relative">
                  <label>Password</label>
                  <span
                    className="eyeicon"
                    onClick={() =>
                      showPwd !== true ? setShowPwd(true) : setShowPwd(false)
                    }
                  >
                    {showPwd === false ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                  </span>
                  <input
                    type={showPwd ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3 position-relative">
                  <label>Confirm Password</label>
                  <span
                    className="eyeicon"
                    onClick={() =>
                      showConfPwd !== true
                        ? setShowConfPwd(true)
                        : setShowConfPwd(false)
                    }
                  >
                    {showConfPwd === false ? (
                      <HiOutlineEyeOff />
                    ) : (
                      <HiOutlineEye />
                    )}
                  </span>
                  <input
                    type={showConfPwd ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.confirmPassword ? (
                    <div className="error">{formik.errors.confirmPassword}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Select Country</label>
                  <CustomSelect
                    value={formik.values.country_id}
                    onChange={(value) => {
                      formik.setFieldValue("country_id", value.value);
                      setCountryShortname(value.shortname.toLowerCase());
                    }}
                    options={country}
                  />
                  {formik.errors.country_id ? (
                    <div className="error">{formik.errors.country_id}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Select State</label>
                  <CustomSelect
                    value={formik.values.state_id}
                    onChange={(value) =>
                      formik.setFieldValue("state_id", value.value)
                    }
                    options={state}
                  />
                  {formik.errors.state_id ? (
                    <div className="error">{formik.errors.state_id}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Select City</label>
                  <CustomSelect
                    value={formik.values.city_id}
                    onChange={(value) =>
                      formik.setFieldValue("city_id", value.value)
                    }
                    options={cities}
                  />
                  {formik.errors.city_id ? (
                    <div className="error">{formik.errors.city_id}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.address ? (
                    <div className="error">{formik.errors.address}</div>
                  ) : null}
                </Col>{" "}
                <Col className="col-md-5 col-12 mb-3">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of Birth"
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.dob ? (
                    <div className="error">{formik.errors.dob}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Mobile</label>
                  <PhoneInput
                    showFlags={true}
                    specialLabel={false}
                    countryCodeEditable={false}
                    country={countryShortname}
                    value={formik.values.mobile}
                    onChange={(phone, country, e, formattedValue) => {
                      formik.setFieldValue("mobile", formattedValue);
                    }}
                  />

                  {formik.errors.mobile ? (
                    <div className="error">{formik.errors.mobile}</div>
                  ) : null}
                </Col>{" "}
                <Col className="col-md-5 col-12 mb-3"></Col>
              </Row>
            ) : formStep === 2 ? (
              <Row className="justify-content-center">
                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Select Currency</label>
                  <CustomSelect
                    value={formik.values.currency_id}
                    onChange={(value) =>
                      formik.setFieldValue("currency_id", value.value)
                    }
                    options={currency}
                  />
                  {formik.errors.currency_id ? (
                    <div className="error">{formik.errors.currency_id}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Select Language</label>
                  <CustomSelect
                    value={formik.values.language_id}
                    onChange={(value) =>
                      formik.setFieldValue("language_id", value.value)
                    }
                    options={language}
                  />
                  {formik.errors.language_id ? (
                    <div className="error">{formik.errors.language_id}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 col-12 mb-3">
                  <label>Your Domain Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Domain Name"
                    name="domain_name"
                    value={formik.values.domain_name}
                    onChange={formik.handleChange}
                  />
                  {formik.values.domain_name.length > 0 ? (
                    <div className="error">{domainCheck}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 mb-lg-4 col-12 mb-3">
                  <label>Training Set Up</label>
                  <CustomSelect
                    value={formik.values.training_setup}
                    onChange={(value) =>
                      formik.setFieldValue("training_setup", value.value)
                    }
                    options={[
                      { value: 1, label: "Single Sport Academy" },
                      {
                        value: 2,
                        label: "Multiple Sports Discipline Academy / Club",
                      },
                      { value: 3, label: "Schools Sports Program" },
                      { value: 4, label: "Sports Federation" },
                      { value: 5, label: "Individual Training Sessions" },
                      { value: 6, label: "Others" },
                    ]}
                  />
                  {formik.errors.training_setup ? (
                    <div className="error">{formik.errors.training_setup}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 col-12 mb-3">
                  <label>Enter Other</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Other"
                    name="enter_other"
                    value={formik.values.enter_other}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.enter_other ? (
                    <div className="error">{formik.errors.enter_other}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 col-12 mb-3">
                  <label>Section to Disposal</label>
                  <CustomSelect
                    value={formik.values.section_to_disposal}
                    onChange={(value) =>
                      formik.setFieldValue("section_to_disposal", value.value)
                    }
                    options={[
                      { value: 1, label: "Home Page for your Business" },
                      { value: 2, label: "Business Data Analytics" },
                      { value: 3, label: "Players Registrations" },
                      { value: 4, label: "Coaches Registrations" },
                      { value: 5, label: "Session Scheduling Service" },
                      { value: 6, label: "Academy Management Module" },
                      { value: 7, label: "Court Booking" },
                      { value: 8, label: "Private Sessions Booking" },
                      { value: 9, label: "Gallery" },
                      { value: 10, label: "Players Profiles" },
                      { value: 11, label: "E Cart (Online Selling)" },
                    ]}
                  />
                  {formik.errors.section_to_disposal ? (
                    <div className="error">
                      {formik.errors.section_to_disposal}
                    </div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3">
                  <label>Select Sport</label>
                  <CustomSelect
                    isMulti={true}
                    value={formik.values.academy_sports}
                    onChange={(value) => {
                      formik.setFieldValue(
                        "academy_sports",
                        value.map((v) => v.value)
                      );
                    }}
                    options={sport}
                  />
                  {formik.errors.academy_sports ? (
                    <div className="error">{formik.errors.academy_sports}</div>
                  ) : null}
                </Col>

                <Col className="col-md-5 col-12 mb-3">
                  <label>Number of Players</label>
                  <CustomSelect
                    value={formik.values.number_of_players}
                    onChange={(value) =>
                      formik.setFieldValue("number_of_players", value.label)
                    }
                    options={[
                      { value: 1, label: "0-10" },
                      { value: 2, label: "11-30" },
                      { value: 3, label: "31-50" },
                      { value: 4, label: "51-100" },
                      { value: 5, label: "More than 100" },
                    ]}
                  />
                  {formik.errors.number_of_players ? (
                    <div className="error">
                      {formik.errors.number_of_players}
                    </div>
                  ) : null}
                </Col>

                <Col className="col-md-5 col-12 mb-3">
                  <label>Approx Number</label>
                  <CustomSelect
                    value={formik.values.approx_number}
                    onChange={(value) =>
                      formik.setFieldValue("approx_number", value.value)
                    }
                    options={[
                      { value: 1, label: "0-10" },
                      { value: 2, label: "11-30" },
                      { value: 3, label: "31-50" },
                      { value: 4, label: "51-100" },
                      { value: 5, label: "More than 100" },
                    ]}
                  />
                  {formik.errors.approx_number ? (
                    <div className="error">{formik.errors.approx_number}</div>
                  ) : null}
                </Col>
                <Col className="col-md-5 col-12 mb-3"></Col>
              </Row>
            ) : formStep === 3 ? (
              <>
                <Row
                  className={`${
                    togglePaymentForm === "code" ? "activeForm" : ""
                  } justify-content-center`}
                >
                  <Col className="col-md-5 col-12 mb-3">
                    <label>Bypass Type</label>
                    <CustomSelect
                      value={formik.values.bypass_type}
                      onChange={(value) =>
                        formik.setFieldValue("bypass_type", value.value)
                      }
                      options={[
                        { value: "code", label: "Code" },
                        { value: "card", label: "Card" },
                      ]}
                    />
                    {formik.errors.bypass_type ? (
                      <div className="error">{formik.errors.bypass_type}</div>
                    ) : null}
                  </Col>
                  <Col className="col-md-5 col-12 mb-3"></Col>

                  {formik.values.bypass_type === "code" ? (
                    <>
                      <Col className="col-md-5 col-12 mb-3">
                        <label>Bypass Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bypass Code"
                          name="bypass_code"
                          value={formik.values.bypass_code}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.bypass_code ? (
                          <div className="error">
                            {formik.errors.bypass_code}
                          </div>
                        ) : null}
                      </Col>
                      <Col className="col-md-5 col-12 mb-3"></Col>
                    </>
                  ) : (
                    <Col className="col-md-10 col-12 mb-3">
                      <hr />
                      <Row>
                        <Col className="col-md-6 col-12 mb-3">
                          <label>Card Holder Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Card Holder Name"
                            name="academy_card.card_holder"
                            value={formik.values?.academy_card?.card_holder}
                            onChange={formik.handleChange}
                          />
                          {formik.errors?.academy_card?.card_holder ? (
                            <div className="error">
                              {formik.errors?.academy_card?.card_holder}
                            </div>
                          ) : null}
                        </Col>
                        <Col className="col-md-6 col-12 mb-3">
                          <label>Card Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Card Number"
                            name="academy_card.card_number"
                            value={formik.values?.academy_card?.card_number}
                            onChange={formik.handleChange}
                          />
                          {formik.errors?.academy_card?.card_number ? (
                            <div className="error">
                              {formik.errors?.academy_card?.card_number}
                            </div>
                          ) : null}
                        </Col>
                        <Col className="col  mb-3">
                          <label>Exp Date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Exp Date"
                            name="academy_card.exp_date"
                            value={formik.values?.academy_card?.exp_date}
                            onChange={formik.handleChange}
                          />
                          {formik.errors?.academy_card?.exp_date ? (
                            <div className="error">
                              {formik.errors?.academy_card?.exp_date}
                            </div>
                          ) : null}
                        </Col>
                        <Col className="col  mb-3">
                          <label>CVV</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="CVV"
                            name="academy_card.cvv"
                            value={formik.values?.academy_card?.cvv}
                            onChange={formik.handleChange}
                          />
                          {formik.errors?.academy_card?.cvv ? (
                            <div className="error">
                              {formik.errors?.academy_card?.cvv}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            ) : null}

            <Row className="justify-content-center">
              <Col className="col-md-10 col-12 text-end mb-3 ">
                {formStep > 1 ? (
                  <Button
                    onClick={() => setFormStep(formStep - 1)}
                    type="button"
                    className="px-md-5"
                    variant="primary"
                  >
                    Prev
                  </Button>
                ) : null}
                {formStep < stepCount ? (
                  <Button
                    onClick={() => setFormStep(formStep + 1)}
                    type="button"
                    className="px-md-5 ms-3"
                    variant="primary"
                  >
                    Next
                  </Button>
                ) : null}
                {formStep === stepCount ? (
                  <Button
                    to="#"
                    type="button"
                    onClick={() => submitForm()}
                    className="px-md-5 ms-3"
                    variant="primary"
                  >
                    {isLoading ? <div className="loader"></div> : "Submit"}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Form>
          {/* <Col className="col-md-10 col-12 mx-auto text-end">
            Already have an Account? <a target="_blank" href="https://ac.hitcourt.com/academy-login">Login</a>
          </Col> */}
        </Col>
        <Col
          lg={6}
          className="d-lg-flex d-none align-items-center h-100 auth-right py-md-5 py-3 overflow-auto"
        >
          <div className=" px-lg-5 px-lg-3">
            <Image
              src={require("../../public/images/login_Img.png")}
              height="150"
            />
            <h2 className="text-white">Welcome to EWizPro Signup Page</h2>
            <h5 className="text-white mb-lg-4 my-3">
              Do send us your intent, and we will get back to you shortly
            </h5>
            <h5 className="text-white mb-lg-4 my-3">Thank You</h5>
            {/* <ListGroup as="ol" variant="flush">
							<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start py-4 border-0">
								<div className="checkicon">
									<FiCheck />
								</div>
								<div className="ms-2 me-auto">
									<div className="fw-bold h5">Talk to Sports Expert</div>
									Lorem ipsum dolor sit amet consectetur adipisicing el
								</div>
							</ListGroup.Item>
							<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start py-4 border-0">
								<div className="checkicon">
									<FiCheck />
								</div>
								<div className="ms-2 me-auto">
									<div className="fw-bold h5">Talk to Sports Expert</div>
									Lorem ipsum dolor sit amet consectetur adipisicing el
								</div>
							</ListGroup.Item>
							<ListGroup.Item as="li" className="d-flex justify-content-between align-items-start py-4 border-0">
								<div className="checkicon">
									<FiCheck />
								</div>
								<div className="ms-2 me-auto">
									<div className="fw-bold h5">Talk to Sports Expert</div>
									Lorem ipsum dolor sit amet consectetur adipisicing el
								</div>
							</ListGroup.Item>
						</ListGroup> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
