import React, { useState } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { BsFillPatchCheckFill } from "react-icons/bs";

const customers = [
  {
    title: "IMG Academy",
    address: "5650 Bollettieri Blvd, Bradenton, FL 34210, USA",
    description:
      "IMG Academy, founded by Nick Bollettieri, is one of the most prestigious tennis academies in the world. It offers comprehensive tennis training programs for juniors and professionals, emphasizing physical conditioning, mental toughness, and technical skills. The academy also provides education programs, making it ideal for student-athletes.",
    other_info: {
      website: "https://www.imgacademy.com",
      founded: 1978,
      notable_alumni: ["Andre Agassi", "Maria Sharapova", "Serena Williams"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Mouratoglou Tennis Academy",
    address: "3550 Route des Dolines, 06410 Biot, France",
    description:
      "The Mouratoglou Tennis Academy, founded by renowned coach Patrick Mouratoglou, is known for its high-performance training programs. The academy focuses on developing players' technical, tactical, and physical aspects, offering customized training plans to meet individual needs. It also combines intensive tennis training with academic education.",
    other_info: {
      website: "https://www.mouratoglou.com",
      founded: 1996,

      notable_alumni: ["Stefanos Tsitsipas", "Coco Gauff"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Rafael Nadal Academy",
    address:
      "Ctra. Cales de Mallorca s/n, Km 1.2, 07500 Manacor, Balearic Islands, Spain",
    description:
      "Founded by tennis legend Rafael Nadal, the Rafael Nadal Academy provides world-class tennis training and education. The academy offers programs for all levels, focusing on technical, physical, and mental training. The state-of-the-art facilities and experienced coaching staff help players reach their full potential.",
    other_info: {
      website: "https://www.rafanadalacademy.com",
      founded: 2016,
      notable_alumni: ["Casper Ruud", "Jaume Munar"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Saddlebrook Tennis Academy",
    address: "5700 Saddlebrook Way, Wesley Chapel, FL 33543, USA",
    description:
      "Saddlebrook Tennis Academy offers top-notch tennis training programs for juniors and adults. The academy focuses on developing players' skills through personalized coaching, conditioning, and mental training. It also provides a unique blend of academics and athletics for student-athletes.",
    other_info: {
      website: "https://www.saddlebrook.com/tennis",
      founded: 1986,
      notable_alumni: ["Jim Courier", "Jennifer Capriati"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Evert Tennis Academy",
    address: "10334 Diego Dr S, Boca Raton, FL 33428, USA",
    description:
      "Founded by tennis great Chris Evert, the Evert Tennis Academy is dedicated to providing elite tennis training. The academy offers a comprehensive program that includes technical, tactical, and physical training, along with mental conditioning. It also offers academic programs for student-athletes.",
    other_info: {
      website: "https://www.evertacademy.com",
      founded: 1996,

      notable_alumni: ["Madison Keys", "Lauren Davis"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Evert Tennis Academy",
    address: "10334 Diego Dr S, Boca Raton, FL 33428, USA",
    description:
      "Founded by tennis great Chris Evert, the Evert Tennis Academy is dedicated to providing elite tennis training. The academy offers a comprehensive program that includes technical, tactical, and physical training, along with mental conditioning. It also offers academic programs for student-athletes.",
    other_info: {
      website: "https://www.evertacademy.com",
      founded: 1996,

      notable_alumni: ["Madison Keys", "Lauren Davis"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Evert Tennis Academy",
    address: "10334 Diego Dr S, Boca Raton, FL 33428, USA",
    description:
      "Founded by tennis great Chris Evert, the Evert Tennis Academy is dedicated to providing elite tennis training. The academy offers a comprehensive program that includes technical, tactical, and physical training, along with mental conditioning. It also offers academic programs for student-athletes.",
    other_info: {
      website: "https://www.evertacademy.com",
      founded: 1996,

      notable_alumni: ["Madison Keys", "Lauren Davis"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Evert Tennis Academy",
    address: "10334 Diego Dr S, Boca Raton, FL 33428, USA",
    description:
      "Founded by tennis great Chris Evert, the Evert Tennis Academy is dedicated to providing elite tennis training. The academy offers a comprehensive program that includes technical, tactical, and physical training, along with mental conditioning. It also offers academic programs for student-athletes.",
    other_info: {
      website: "https://www.evertacademy.com",
      founded: 1996,

      notable_alumni: ["Madison Keys", "Lauren Davis"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
  {
    title: "Evert Tennis Academy",
    address: "10334 Diego Dr S, Boca Raton, FL 33428, USA",
    description:
      "Founded by tennis great Chris Evert, the Evert Tennis Academy is dedicated to providing elite tennis training. The academy offers a comprehensive program that includes technical, tactical, and physical training, along with mental conditioning. It also offers academic programs for student-athletes.",
    other_info: {
      website: "https://www.evertacademy.com",
      founded: 1996,

      notable_alumni: ["Madison Keys", "Lauren Davis"],
    },
    img: "https://img.freepik.com/free-vector/figure-folded-logo_1043-97.jpg",
  },
];

const CustomerPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleShow = (customer) => {
    setSelectedCustomer(customer);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedCustomer(null);
  };

  return (
    <>
      <Container className="top-150 mb-5">
        <Row className="g-4 align-items-xl-center">
          <Col lg={4}>
            <Row>
              {/* <Col sm={6}>
                <img
                  src="https://img.freepik.com/free-photo/portrait-young-person-playing-professional-tennis_23-2151418059.jpg"
                  className="rounded w-100"
                  alt="about-img"
                />
              </Col> */}

              <Col sm={12}>
               
                <img
                  src="https://img.freepik.com/free-photo/view-tennis-player-with-digital-art-style-effect_23-2151737676.jpg"
                  className="rounded w-100"
                  alt="about-img-2" style={{ objectFit:'cover'}}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={8}>
            
            <span className="heading-color bg-light small rounded-3 px-3 py-2">
              🌟 Trusted by the Best
            </span>
            <h2 className="my-4">
              <b>Partnering with Leaders</b> in the Tennis World!
            </h2>
            <p className="mb-4">
              Our commitment to excellence has made us the go-to choice for top
              tennis academies around the globe. We collaborate with industry
              leaders to bring out the best in every player, ensuring a winning
              partnership for success.
            </p>
            {/* <!-- List --> */}
            <ul className="list-group list-group-borderless mb-4">
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />
                Proven track record of success
              </li>
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />
                Expertise in training and development
              </li>
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />
                Trusted by top-tier academies
              </li>
            </ul>
            <Row className="mb-4">
                  <Col sm={6} md={4} className="mb-4 mb-sm-0">
                    <div className="bg-dark text-white rounded text-center p-3">
                      <span className="h2 text-white">10+</span>
                      <p className="mb-0">Years of excellence</p>
                    </div>
                  </Col>
                  <Col sm={6} md={4}>
                    <div className="bg-primary rounded text-center p-3">
                      <span className="h2 text-white">1.2K</span>
                      <p className="mb-0 text-white">Trusted clients</p>
                    </div>
                  </Col>
                </Row>
          </Col>
          {/* <!-- About content END --> */}
        </Row>
      </Container>

      {/* Main Content Section */}
      <Container className="my-5">
        <Row className="text-center mb-5">
          <Col>
            <h2 className="fw-bold">Explore Our Academies</h2>
          </Col>
        </Row>

        <Row className="g-4">
          {customers.map((customer, index) => (
            <Col md={4} className="overflow-hidden" key={index}>
              <Card
                onClick={() => handleShow(customer)}
                className="customer-card"
              >
                <Card.Img
                  variant="top"
                  src={customer.img}
                  alt={customer.title}
                />
                <Card.Body className="customer-card-body position-absolute pb-3">
                  <Card.Title className="card_title">
                    {customer.title}
                  </Card.Title>
                  <Card.Text className="card_dec">
                    {customer.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedCustomer && (
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedCustomer.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={selectedCustomer.img} className="w-100" />
              <p>
                <strong>Address:</strong> {selectedCustomer.address}
              </p>
              <p>{selectedCustomer.description}</p>
              <p>
                <strong>Website:</strong>{" "}
                <a
                  href={selectedCustomer.other_info.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedCustomer.other_info.website}
                </a>
              </p>
              <p>
                <strong>Founded:</strong> {selectedCustomer.other_info.founded}
              </p>
              <p>
                <strong>Notable Alumni:</strong>{" "}
                {selectedCustomer.other_info.notable_alumni.join(", ")}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default CustomerPage;
