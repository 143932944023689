import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import SvgLogo from './SvgLogo';

export default function Header() {
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	offset > 50 ? document.querySelector('body').classList.add('scrolled') : document.querySelector('body').classList.remove('scrolled');

	return (
		<Navbar expand="lg" className="py-0 fixed-top shadow">
			<Container fluid>
				<Navbar.Brand as={Link} to="/">
					<SvgLogo color1={'#FF5C00'} color2={'#000000'} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto">
						<Nav.Link as={Link} to="/">
							Home
						</Nav.Link>
						{/* <Nav.Link as={Link} to="our-customers">
							Our Clients
						</Nav.Link> */}
						<Nav.Link as={Link} to="contact">
							Contact
						</Nav.Link>
						<Nav.Link as={Link} to="academy-register">
							Register
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
