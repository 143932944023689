import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Service from "../http";
import LocalStorage from "../components/LocalStorage";
import { useNavigate } from "react-router-dom";

const services = new Service();
// Define initialstate
const initState = {
  is_fetching: false,
  is_error: false,
  is_success: false,
  error_message: "",
  user: {},
  user_type: LocalStorage.getValue("usertype") || "",
  access_token: LocalStorage.getValue("userinfo") || "",
};

// Login users: Player, Coach, Academy etc.
export const loginRequest = createAsyncThunk(
  "login/request",
  async ({ loginURL, email, password }, thunkAPI) => {
    try {
      const response = await services.post(loginURL, { email, password });
      if (response.status) {
        let resData = response.data;
        LocalStorage.setValue("usertype", resData.user_type);
        LocalStorage.setValue("userinfo", resData.access_token);
        LocalStorage.setValue(
          "is_profile_completed",
          resData.is_profile_completed
        );

        setTimeout(() => {
          window.location.href = "/";
        }, 300);
        return resData;
      } else {
        toast(response.message[0].message);
        return thunkAPI.rejectWithValue(response.message[0].message);
      }
    } catch (err) {
      toast(err.message);
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const loginSlice = createSlice({
  name: "Login",
  initialState: initState,
  reducers: {
    // clearState(state) {
    //   Object.assign(state, initState);
    // },
    clearState: () => initState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.is_fetching = true;
      })
      .addCase(loginRequest.fulfilled, (state, { payload }) => {
        state.is_fetching = false;
        state.is_error = false;
        state.is_success = true;
        state.error_message = "";
        state.user = payload;
        state.user_type = payload.user_type;
        state.access_token = payload.access_token;
      })
      .addCase(loginRequest.rejected, (state, { payload }) => {
        console.log("Rejected", payload);
        state.is_fetching = false;
        state.is_success = false;
        state.is_error = true;
        state.error_message = payload.error || payload.message;
      });
  },
});

export const { clearState, loginAction, logoutAction } = loginSlice.actions;
export default loginSlice.reducer;
