import { createSlice } from "@reduxjs/toolkit";

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countries: [],
  },
  reducers: {
    fetchCountry: (state, { payload }) => {
      state.countries = payload.countries;
    },
  },
});

export const { fetchCountry } = countrySlice.actions;
export default countrySlice.reducer;
