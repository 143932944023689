import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Home from '../Pages/frontend/Frontend';

import AcademyRegister from '../Auth/Academy/AcademyRegister';
import Features from '../Pages/Features';
import Header from '../Pages/frontend/Header';
import Footer from '../Pages/frontend/Footer';
import FrontLayout from '../Pages/frontend/FrontLayout';
import CustomerPage from '../Pages/CustomerPage';
import RequestForm from '../Pages/Contact/RequestForm';

export default function RouteFile() {
	const navigate = useNavigate();

	const loginStatus = useSelector((state) => state.login.user_type);

	return (
		<>
			<div className="wrapper">
				<div className="content">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route element={<FrontLayout />}>
							<Route path="academy-register" element={<AcademyRegister header={false} />} />
							<Route path="features" element={<Features />} />
							<Route path="our-customers" element={<CustomerPage />} />
							<Route path="contact" element={<RequestForm />} />

						</Route>
						<Route path="*" element={<Home />} />
					</Routes>
				</div>
			</div>
		</>
	);
}
