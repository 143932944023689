import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
} from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { useState } from "react";
import Label from "../../components/Label";
import { BsFillPatchCheckFill } from "react-icons/bs";
import CustomInput from "../../components/CustomInput";
import Service from "../../http";
import CustomSelect from "../../components/CustomSelect";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  mobile: Yup.string().required("Required"),
  academy_name: Yup.string().required("Required"),
  sport_id: Yup.number().required("Required"),
  country_id: Yup.number().nullable().required("Required"),
  state_id: Yup.number().nullable().required("Required"),
  city_id: Yup.number().nullable().required("Required"),
  zip_code: Yup.number().nullable(),
});

const countryList = [{ label: "Select Country", value: "" }];
const stateList = [{ label: "Select State", value: "" }];
const cityList = [{ label: "Select City", value: "" }];

export default function RequestForm() {
  const services = new Service();
  const [isLoader, setIsLoader] = useState(false);
  const [ewizproMarketingConsent, setEwizproMarketingConsent] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState([]);
  const [countryShortname, setCountryShortname] = useState("");
  const [sport, setSport] = useState([{ label: "Select Sport", value: null }]);
  const [playground_type, setPlayground_type] = useState(false);
  const [countryInfo, setCountryInfo] = useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      academy_name: "",
      sport_id: "",
      no_of_courts: null,
      country_id: null,
      state_id: null,
      city_id: null,
      zip_code: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoader(true);
      services &&
        services.post("contact", values).then((res) => {
          if (res.status) {
            toast(res.message);
            resetForm();
            setTimeout(() => {
              window.location.reload()
            }, 1000);
          } else {
            toast(res.message[0].message);
            toast(res.message);
          }
          setIsLoader(false);
        });
    },
  });

  React.useEffect(() => {
    services &&
      services.get("country").then((res) => {
        // countryList
        res.data.map((i) => {
          const singleCountry = {};
          singleCountry.label = i.name;
          singleCountry.value = i.id;
          singleCountry.shortname = i.shortname;
          countryList.push(singleCountry);
        });
        setCountry(countryList);
      });
  }, []);

  React.useEffect(() => {
    stateList.splice(0, stateList.length);
    services &&
      services.get("states").then((res) => {
        res.data.map((i) => {
          if (i.country_id === formik.values.country_id) {
            const singleState = {};
            singleState.label = i.name;
            singleState.value = i.id;
            stateList.push(singleState);

            setState(stateList);
          }
        });
      });
  }, [formik.values.country_id]);

  React.useEffect(() => {
    cityList.splice(0, cityList.length);
    services &&
      services.get("cities").then((res) => {
        res.data.map((i) => {
          if (i.state_id === formik.values.state_id) {
            const singleCity = {};
            singleCity.label = i.name;
            singleCity.value = i.id;
            cityList.push(singleCity);

            setCities(cityList);
          }
        });
      });
  }, [formik.values.state_id]);

  React.useEffect(() => {
    services &&
      services.get("sports/listview").then((res) => {
        setSport([]);
        res.data.content.map((i) => {
          let singleSport = {};
          singleSport.label = i.name_en;
          singleSport.value = i.id;
          setSport((old) => [...old, singleSport]);
        });
      });
  }, []);

  React.useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "https://api.geoapify.com/v1/ipinfo?apiKey=b8568cb9afc64fad861a69edbddb2658"
        );
        const countryitem = country&&country.find(
          (c) => c.shortname === response.data?.country?.iso_code
        );
        formik.setFieldValue("country_id", countryitem?.value);
        setCountryInfo(countryitem?.shortname);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountry();
  }, [country]);

  React.useEffect(() => {
    const countryitem = country.find(
      (c) => c.value === formik.values.country_id
    );
    setCountryInfo(countryitem?.shortname);
  }, [formik.values.country_id]);

  return (
    <>
      <Container className="top-150 mb-5">
        <Row className="g-4 align-items-xl-center">
          <Col lg="7">
            <Row>
              <Col sm={10}>
                <img
                  src="https://img.freepik.com/free-photo/portrait-young-person-playing-professional-tennis_23-2151418059.jpg"
                  className="rounded w-100"
                  alt="about-img"
                />
              </Col>
            </Row>
          </Col>

          <Col lg={5}>
            <span className="heading-color bg-light small rounded-3 px-3 py-2">
              🌟 Contact Our Experts
            </span>
            <h2 className="my-4">
              <b>Get in Touch</b> with Tennis Experts!
            </h2>
            <p className="mb-4">
              If you have any questions or would like more information about our
              services, our team of tennis experts is here to help. Contact us
              today, and we'll provide the guidance you need to achieve your
              goals.
            </p>
            <ul className="list-group list-group-borderless mb-4">
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />
                Personalized advice from industry leaders
              </li>
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />
                Expert support tailored to your needs
              </li>
              <li className="list-group-item heading-color d-flex mb-0">
                <BsFillPatchCheckFill className="text-primary me-2 mt-1 h6" />A
                trusted partner in your tennis journey
              </li>
            </ul>

            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="col-lg-6 col-12 mb-3">
                  <Label htmlFor="name" aria-required="true">
                    Name
                  </Label>
                  <CustomInput
                    id="name"
                    validate={formik.touched.name && formik.errors.name}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <Label htmlFor="email" aria-required="true">
                    Email
                  </Label>
                  <CustomInput
                    id="email"
                    validate={formik.touched.email && formik.errors.email}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <Label htmlFor="academy_name" aria-required="true">
                    Academy Name
                  </Label>
                  <CustomInput
                    id="academy_name"
                    validate={
                      formik.touched.academy_name && formik.errors.academy_name
                    }
                    type="text"
                    className="form-control"
                    placeholder="Academy Name"
                    name="academy_name"
                    value={formik.values.academy_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.academy_name && formik.errors.academy_name ? (
                    <div className="error">{formik.errors.academy_name}</div>
                  ) : null}
                </Col>
                {/* Continue with the rest of your form fields */}
                <Col className="col-lg-6 col-12 mb-3">
                  <Label htmlFor="sport_id" aria-required="true">
                    Sport
                  </Label>
                  <CustomSelect
                    id="sport_id"
                    validate={formik.touched.sport_id && formik.errors.sport_id}
                    value={formik.values.sport_id}
                    onChange={(value) =>
                      formik.setFieldValue("sport_id", value.value)
                    }
                    options={sport}
                  />
                  {formik.touched.sport_id && formik.errors.sport_id ? (
                    <div className="error">{formik.errors.sport_id}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <label>Select Country</label>
                  <CustomSelect
                    value={formik.values.country_id}
                    onChange={(value) => {
                      formik.setFieldValue("country_id", value.value);
                      setCountryShortname(value.shortname.toLowerCase());
                    }}
                    options={country}
                  />
                  {formik.errors.country_id ? (
                    <div className="error">{formik.errors.country_id}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <label>Select State</label>
                  <CustomSelect
                    value={formik.values.state_id}
                    onChange={(value) =>
                      formik.setFieldValue("state_id", value.value)
                    }
                    options={state}
                  />
                  {formik.errors.state_id ? (
                    <div className="error">{formik.errors.state_id}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <label>Select City</label>
                  <CustomSelect
                    value={formik.values.city_id}
                    onChange={(value) =>
                      formik.setFieldValue("city_id", value.value)
                    }
                    options={cities}
                  />
                  {formik.errors.city_id ? (
                    <div className="error">{formik.errors.city_id}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <Label htmlFor="zip_code">Zip Code</Label>
                  <CustomInput
                    id="zip_code"
                    validate={formik.touched.zip_code && formik.errors.zip_code}
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    name="zip_code"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.zip_code && formik.errors.zip_code ? (
                    <div className="error">{formik.errors.zip_code}</div>
                  ) : null}
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <label>Mobile</label>
                  <PhoneInput
                    className="w-100"
                    showFlags={true}
                    specialLabel={false}
                    countryCodeEditable={false}
                    country={countryInfo&&countryInfo.toLowerCase()}
                    value={formik.values.mobile}
                    onChange={(phone, country, e, formattedValue) => {
                      formik.setFieldValue("mobile", formattedValue);
                    }}
                  />

                  {formik.errors.mobile ? (
                    <div className="error">{formik.errors.mobile}</div>
                  ) : null}
                </Col>
                
                <Col className="col-lg-12 col-12 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="playergroundType"
                    name="playergroundType"
                    label="Playground type - Court"
                    checked={playground_type}
                    onChange={(e) => setPlayground_type(e.target.checked)}
                  />
                </Col>

                {playground_type && (
                  <Col className="col-lg-6 col-12 mb-3">
                    <Label htmlFor="no_of_courts" aria-required="true">
                      Number of Courts
                    </Label>
                    <CustomInput
                      id="no_of_courts"
                      validate={
                        formik.touched.no_of_courts &&
                        formik.errors.no_of_courts
                      }
                      type="number"
                      className="form-control"
                      placeholder="Number of Courts"
                      name="no_of_courts"
                      value={formik.values.no_of_courts}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.no_of_courts && formik.errors.no_of_courts ? (
                      <div className="error">{formik.errors.no_of_courts}</div>
                    ) : null}
                  </Col>
                )}
                {/* Ewizpro Marketing Communications Consent */}
                <Col className="col-lg-12 col-12 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="ewizproMarketingConsent"
                    name="ewizproMarketingConsent"
                    label="Ewizpro marketing communications: I consent to the processing of my personal data for the purpose of sending marketing communications as indicated in the privacy policy."
                    checked={ewizproMarketingConsent}
                    onChange={(e) =>
                      setEwizproMarketingConsent(e.target.checked)
                    }
                  />
                </Col>
                {/* Privacy Policy Confirmation */}
                <Col className="col-lg-12 col-12 mb-3">
                  <Form.Check
                    type="checkbox"
                    id="privacyPolicy"
                    name="privacyPolicy"
                    label="Privacy policy: I confirm that I have read the privacy policy."
                    checked={privacyPolicy}
                    onChange={(e) => setPrivacyPolicy(e.target.checked)}
                  />
                </Col>
                <Col className="col-lg-12 col-12 mb-3">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={
                      privacyPolicy === false ||
                      ewizproMarketingConsent === false
                    }
                  >
                    {isLoader ? <div className="loader"></div> : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <h5 className="mt-3 mb-4"></h5>
      </Container>
    </>
  );
}
