import { configureStore } from "@reduxjs/toolkit";
import citiesSlice from "./citiesSlice";
import statesSlice from "./statesSlice";
import countrySlice from "./countrySlice";
import loginSlice from "./loginSlice";
export default configureStore({
  reducer: {
    city: citiesSlice,
    state: statesSlice,
    country: countrySlice,
    login: loginSlice,
  },
});
