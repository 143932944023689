import React from "react";
import Select from "react-select";
export default function CustomSelect({
  onChange,
  options,
  value,
  isMulti,
  defaultValue_,
}) {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };
  return (
    <div className="react_select">
      <Select
        isMulti={isMulti}
        defaultValue={defaultValue_}
        value={defaultValue(options, value)}
        onChange={(value) => onChange(value)}
        options={options}
        isOptionDisabled={(option) => option.disabled}
      ></Select>
    </div>
  );
}
