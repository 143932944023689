import React from "react";

export default function Image({ src, className, height, style }) {

  var ext = src!==undefined ? src.replace(/^.*[\\\/]/, "").split(".") : null;
  return (
   
      <img
        className={className}
        src={src!==undefined?src:''}
        height={height}
        alt={'img'}
        style={style}
        // type={`images/${ext[0]}`}
        />
 
  );
}