import { createSlice } from "@reduxjs/toolkit";

export const citiesSlice = createSlice({
  name: "city",
  initialState: {
    cities: [],
  },
  reducers: {
    fetchCity: (state, { payload }) => {
      state.cities = payload.cities;
    },
  },
});

export const { fetchCity } = citiesSlice.actions;
export default citiesSlice.reducer;
