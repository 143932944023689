import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./includes/Footer";
import Sidebar from "./includes/Sidebar";
import RouteFile from "./RouteFolder/RouteFile";
import "./public/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
import { fetchCity } from "./redux/citiesSlice";
import { fetchState } from "./redux/statesSlice";
import { fetchCountry } from "./redux/countrySlice";
import RequestForm from "./Pages/Contact/RequestForm"
import Service from "./http";
import { ToastContainer } from "react-toastify";

function App() {
  const services = new Service();

  const dispatch = useDispatch();

  useEffect(() => {
    // services.get("cities").then((res) => {
    //   dispatch(fetchCity(res.data));
    // });
    // services.get("states").then((res) => {
    //   dispatch(fetchState(res.data));
    // });
    // services.get("country").then((res) => {
    //   dispatch(fetchCountry(res.data));
    // });
  }, []);

  return (
    <div className="App">
      <ToastContainer/>
      <RouteFile />
    </div>
  );
}

export default App;
