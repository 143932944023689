import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

export default function Features() {
  return (
    <div className="frontend headerOffset">
      <Container className="mt-5">
        {/* Scheduling Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={12} md={4} className="pe-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/calendar-planner-agenda-schedule-concept_53876-133696.jpg?t=st=1712127331~exp=1712130931~hmac=a247dc9794d38f553dcf7d70b1c87174c0551ccc8d2ee75a7cc44f14ac3546dc&w=800"
                fluid
              />
            </Col>
            <Col xs={12} md={8}>
              {/* Scheduling Content */}
              <h2>
                MEMBER MANAGEMENT (REGISTRATION OF PLAYERS / COACHES / CLUB
                MEMBERS)
              </h2>
              <p>
                EWizPro Automation allows its users to register the various
                stake holders or their Club / Academy.
              </p>
              <p>
                A simple registration form is required to be submitted, taking
                no more than 120 seconds to complete. An automated email is
                received by those registering, delivering their details in their
                email box.
              </p>
              <p>
                A simple “Activation” is required from the Admin, confirming the
                membership, and Academy Members are then eligible to log into
                their exclusive User Zones, and edit their profiles.
              </p>
              <p>
                Once Activated, these Users can be assigned to Academy Programs,
                which are created based on age, skill level and playing
                abilities etc.
              </p>
              <p>
                Academy Admin is able to Disable / Enable members, view the
                Schedules, Manage Payments, & map Players Progress in one click.
              </p>
            </Col>
          </Row>
        </section>

        {/* Member Management Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={{ order: "last", span: 12 }} md={4} className="ps-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/finger-pressing-red-icon_1232-178.jpg?t=st=1712128656~exp=1712132256~hmac=370d44e4fc4bb1236e1f9a7760b6d8e32c9759e786281fdcd6e63cb8eeabb704&w=800"
                fluid
              />
            </Col>
            <Col xs={{ order: "first", span: 12 }} md={8}>
              {/* Member Management Content */}
              <h2>
                {" "}
                STATIONS REVENUE MANAGEMENT (CREATION & BOOKING OF COURTS /
                SPORTS STATIONS)
              </h2>
              <p>
                EWizPro Automation supports creation and management of Sports
                Stations (Courts), and revenue management arising out of
                operating these stations.{" "}
              </p>
              <p>
                Users can, not just create Courts / Sports Stations, but also
                assign their operating / booking cost using a complex
                combination of separating costs for Members & Non-Members if
                need be. Further, Stations (Courts) can be reserved for Members
                if required using the properties section, thus solving complex
                real time situations on ground.{" "}
              </p>
              <p>
                Of course, these Courts / Stations can be booked by Members by
                logging in to their online Members Zone via their mobile phones
                or pads. Each booking creates accounts receivable which can be
                adjusted either via straight counter payments or via online
                Wallets{" "}
              </p>
            </Col>
          </Row>
        </section>

        {/* Accounts Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={12} md={4} className="pe-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/accountant-calculating-profit-with-financial-analysis-graphs_74855-4937.jpg?t=st=1712128697~exp=1712132297~hmac=151888f68348042eca011ecdb40de2593ba2855c79752877bd697b011eaf1286&w=800"
                fluid
              />
            </Col>
            <Col xs={12} md={8}>
              {/* Accounts Content */}
              <h2>
                SCHEDULING (ACADEMY / CLUB PROGRAMS) WITH COACHES & (PLAYERS /
                MEMBERS)
              </h2>
              <p>
                EWizPro Automation supports multiple location feature, whereby
                multiple centers can be managed seamlessly from one point (one
                login).
              </p>
              <p>
                Academy / Club Programs can be scheduled bringing together
                Players / Members with Coaches / Trainers under the pre-created
                Programs. Training sessions thus scheduled can be communicated
                to participants via emails.
              </p>
              <p>
                Scheduling Players / Members on Court Booking charts allows easy
                revenue management.
              </p>
              <p>
                It also allows Private Sessions of Academy Coaches, to be
                scheduled and accounted for, thus bringing about clarity in
                revenue generation and management.
              </p>
            </Col>
          </Row>
        </section>

        {/* Smart Tools Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={{ order: "last", span: 12 }} md={4} className="ps-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/maintenance-tools-kit-icon-graphicon-digital-device-screen_53876-120419.jpg?t=st=1712129590~exp=1712133190~hmac=6adac3ca02480d9ca35797185540f71fd549c65c71fe9a43d011d056750c8aa7&w=800"
                fluid
              />
            </Col>
            <Col xs={{ order: "first", span: 12 }} md={8}>
              {/* Smart Tools Content */}
              <h2>EFFECTIVE STAFF MANAGEMENT (COACHES / TRAINERS)</h2>
              <p>
                EWizPro Automation allows the Admins to manage the training
                sessions much in advance of their going on floor. Daily / Weekly
                programs and work flows can be created and stored much in
                advance, for the Trainers / Coaches to take note of.
              </p>
              <p>
                These sessions can be edited and updated as and when required,
                thus bringing in flexibility and financial transparency.
              </p>
              <p>
                All lessons / sessions booked online keep everyone in the
                management well informed and brings about a clear and
                transparent working environment.
              </p>
            </Col>
          </Row>
        </section>

        {/* Staff Management Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={12} md={4} className="pe-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/smiling-business-lady-working-with-colleagues_1262-2153.jpg?t=st=1712129665~exp=1712133265~hmac=7ffe5f2a50336cc8a824b13c4d71c58bbb557465369a1f07771c98727a3b83e9&w=800"
                fluid
              />
            </Col>
            <Col xs={12} md={8}>
              {/* Staff Management Content */}
              <h2>SESSION REPORTS (POST COMPLETION OF COACHING SESSIONS)</h2>
              <p>
                In majority of sports training institutes around the world, no
                record of sports training is kept anywhere. The player trains
                with the Coach / Trainer and no data of training is recorded,
                which can be reached out at a future date, to make comparative
                analysis of players progression in sports training.
              </p>
              <p>
                EWizPro Automation allows Coaches / Trainers to punch in quick
                reports of their training sessions with their players / trainees
                which is accessible to them over their phone screens. Just a
                couple of minutes is all it takes for the Coaches / Trainers to
                record what were the areas that they worked on with their
                players in a particular training session.
              </p>
              <p>
                These reports cannot just be auto-mailed to the players, they
                can be kept as records for a future time, which requires
                observation of training progression.
              </p>
            </Col>
          </Row>
        </section>

        {/* Smart Reports Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={{ order: "last", span: 12 }} md={4} className="ps-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/paper-analysis_1098-15678.jpg?t=st=1712129688~exp=1712133288~hmac=f47f31d59807be8a04f54ab038312ef3a42e521d08b0fa8afe004811e78cddb2&w=800"
                fluid
              />
            </Col>
            <Col xs={{ order: "first", span: 12 }} md={8}>
              {/* Smart Reports Content */}
              <h2>REVENUE MANAGEMENT (ACADEMY / CLUB WALLET SYSTEM)</h2>
              <p>
                Imagine not having to chase Players / Club Members for paying
                their dues to the Club / Academy. EWizPro Automation provides
                for a Wallet system, where by the Players / Club Members can
                transfer their membership fee straight to the Academy / Club
                bank accounts, and upload a screenshot of their transfers to the
                Academy / Club Admin.
              </p>
              <p>
                The Academy / Club Admin upon receipt of transfer screenshots
                can Credit the Players / Members Academy Wallet, whereby
                allowing them to either pay for their Program Fees, or Private
                Sessions or other financial services utilized by them during the
                course of their membership.{" "}
              </p>
              <p>
                Imagine an automated, intelligent system of sending automatic
                email reminders to various stake holders, when their due dates
                are approaching.{" "}
              </p>
            </Col>
          </Row>
        </section>

        {/* Safe & Secure Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={12} md={4} className="pe-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041848.jpg?t=st=1712129719~exp=1712133319~hmac=d18526ae1465ab7599a9a47c9e0772166a9c27abec5ea57f17b53baaeddaec00&w=800"
                fluid
              />
            </Col>
            <Col xs={12} md={8}>
              {/* Safe & Secure Content */}
              <h2>CUSTOMIZE FRONTEND </h2>
              <p>
                EWiPro Automation allows the Academy owners to customize their
                front ends, upload slider images, feature their best Players /
                Coaches online, write Blogs for their users and a whole lot
                more.
              </p>
              <p>
                All this is made possible by the use of a laptop / computer and
                an internet connection.
              </p>
              <p>
                The Admins can upload images, upload notices in text and image
                format,
              </p>
            </Col>
          </Row>
        </section>

        {/* Intelligent Analysis Section */}
        <section className="section">
          <Row className="align-items-center">
            <Col xs={{ order: "last", span: 12 }} md={4} className="ps-md-5">
              <Image
                className="section_img"
                src="https://img.freepik.com/free-photo/businesswoman-networking-using-digital-devices_53876-101875.jpg?t=st=1712129751~exp=1712133351~hmac=bac72918ea62ce3139fbe002d873c5afa5708b9dae3fb75fd2af6cd817503b58&w=800"
                fluid
              />
            </Col>
            <Col xs={{ order: "first", span: 12 }} md={8}>
              {/* Intelligent Analysis Content */}
              <h2>CUSTOMER SUPPORT </h2>
              <p>
                Excellent Support - We provide full Customer support 24 x 7
                (Except Night Hours), no matter where ever you might be located
                in the world.{" "}
              </p>
              <p>
                We are able to do this with a Live Ticketing System, in which
                people work round the clock to provide solutions, in case any
                bug is encountered or where support is required.{" "}
              </p>
              <p>
                Our Ticketing system assures timely reply to queries sent to us
                providing immediate working solutions.{" "}
              </p>
              <p>
                FAQ & Knowledgebase - Finding the right information at the right
                moment can be a challenge for both your customers and your
                employees. Our knowledge base helps you accomplish both goals in
                order to achieve increased productivity.
              </p>
            </Col>
          </Row>
        </section>

       
      </Container>
    </div>
  );
}
