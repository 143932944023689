import store from "store";

const LocalStorage = {
    /**
     * Set value by key paire into localStorage
     */
    setValue: function (key, value) {
        store.set(key, value);
    },
    /**
     * Get value from localStorage
     */
    getValue: function (key) {
        return store.get(key);
    },
    /**
     * Clear all localStorage
     */
    cleanStore: function () {
        store.clearAll();
    }
};

export default LocalStorage;