import { createSlice } from "@reduxjs/toolkit";

export const statesSlice = createSlice({
  name: "state",
  initialState: {
    states: [],
  },
  reducers: {
    fetchState: (state, { payload }) => {
      state.states = payload.states;
    },
  },
});

export const { fetchState } = statesSlice.actions;
export default statesSlice.reducer;
