import React,{useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import SvgLogo from './SvgLogo';
import {MdOutlineClose} from "react-icons/md"
const Page = () => {
	const [show, setShow] = React.useState(false);
	const [showOther, setShowOther] = React.useState(false);
	const [image, setImage] = useState("")
	React.useEffect(() => {
		AOS.init();
	}, []);

	const enlargeImg = (e) => {
		setImage(e.target.src)
	}
	return (
		<main className="website">
			{image!==""&&<div className='enlarge'><button onClick={()=>setImage("")}><MdOutlineClose /></button><img src={image} className='mw-100 mh-100 shadow-md rounded-lg' alt={image} /></div>}
			<header>
				<nav className="header__nav">
					<div className="header__logo">
						<h4 data-aos="fade-down">
							<SvgLogo color1={'#FF5C00'} color2={'#000000'} />
						</h4>
						<div className="header__logo-overlay"></div>
					</div>

					<ul className="header__menu" data-aos="fade-down">
						{/* <li>
						<Link to="our-customers">
							Our Clients
						</Link>
						</li> */}
						<li>
							<Link to="contact">Contact</Link>
						</li>
						<li>
							<Link to="academy-register">Join Us</Link>
						</li>
					</ul>
				</nav>
			</header>
			<div className="homepage">
				<section className="hero">
					<div className="hero-image">
						<img onClick={(e)=>enlargeImg(e)} src="assets/images/landing-page.webp" alt="a hand holding comet qr menu app" data-aos="fade-up" />
						<div className="hero-image__overlay"></div>
					</div>

					<div className="hero-content">
						<div className="hero-content-info" data-aos="fade-left">
							<h1>
								<span>EWizPro</span> - A Sports Management Solution
							</h1>
							<p>Ewizpro is a cutting-edge SaaS sports management solution which you can use to empower your sports academy / club!</p>
							<p>A one-fits-all solution for all your needs, dive in to explore all the ways it can help you focus on scaling your academy, handling all the grunt work for you.</p>

							<div className="hero-content__buttons">
								<button className="hero-content__order-button">
									<Link to="academy-register">Automate Now!</Link>
								</button>
							</div>
						</div>

						{/* <div className="hero-content__testimonial" data-aos="fade-up">
							<div className="hero-content__customer flex-center">
								<h4>QR</h4>
								<p> & </p>
								<h4>POS</h4>
							</div>

							<div className="hero-content__review">
								<p className="m-0">A best of both worlds - Point of Sales & QR for all your need - Dine-in, In Room Dining and Takeaways! (Delivery coming soon)</p>
							</div>
						</div> */}
					</div>
				</section>

				<section className="about-us" id="about-us">
					<div className="about-us__image">
						<div className="about-us__image-2">
							<img onClick={(e)=>enlargeImg(e)} src="./assets/images/court_booking.jpg" alt="court_booking" data-aos="fade-right" />
						</div>
						<Link to="features">
							<button className="about-us__button">
								Learn More
								<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-up-right.svg" alt="learn more" />
							</button>
						</Link>

						<div className="about-us__image-2">
							<img onClick={(e)=>enlargeImg(e)} src="./assets/images/dashboard.jpg" alt="dashboard" data-aos="fade-right" />
						</div>
					</div>

					<div className="about-us__content" data-aos="fade-left">
						<p className="website__subtitle">EWIZPRO will help you create a platform for you to:</p>
						<h3 className="website__title">Establish your Academy / Club’s Online Presence</h3>
						<ul className="website__description">
							<li>
								<strong>Streamline your academy’s</strong> registrations, financial management, and program coordination
							</li>
							<li>
								<strong>Automate player</strong> and <strong>coach management</strong>, <strong> organize tournaments seamlessly</strong> and <strong>manage your booking systems.</strong>
							</li>
							<li>
								<strong>Showcase Your Training Programs</strong> and Academy / Club Players, Coaches
							</li>
							<li>
								<strong>Share the Story</strong> of your Academy / Club
							</li>
							<li>
								<strong>One place, multiple ways to connect with you</strong> through, Social Media Integrations - WhatsApp, IG Messenger, FB Messenger, Telegram
							</li>
							<li>
								<strong>Overlook the progress</strong> of your academy from anywhere around the world!
							</li>
						</ul>
					</div>
				</section>

				<section className="location" id="menu">
					<h2 className="location__title" data-aos="flip-up">
						A Range of Modules to Serve You
					</h2>

					<div className="location__filters website__hide-scrollbar" data-aos="fade-up">
						<button className="location__filter-btn active">Academy Programs (Group Lessons)</button>
						<button className="location__filter-btn">Training Reports</button>
						<button className="location__filter-btn active">Station Booking Management</button>
						<button className="location__filter-btn">Attendance Management</button>
						<button className="location__filter-btn active">Private Packages (Individual Lessons)</button>
						<button className="location__filter-btn">Email Notifications</button>
						<button className="location__filter-btn active">Academy Accounts</button>
						<button className="location__filter-btn">Tournaments</button>
						<button className="location__filter-btn active">Academy / Club Pro Shop</button>
						<button className="location__filter-btn">Academy Wallet</button>
					</div>

					<button className="location__button" onClick={() => setShowOther(!showOther)}>
						Explore EWIZPRO
						<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-right.svg" alt="arrow-right" />
					</button>
				</section>

				<section className="benefits" id="benefits">
					<section className="benefits-section">
						<div className="benefits__content" data-aos="fade-right">
							<p className="website__subtitle">Efficient Mangement for your Academy:</p>

							<h3 className="website__title">Effective Client & Staff Management</h3>
							<p className="website__description">
								EWizPro Automation allows the Admins to manage the training sessions much in advance of their going on floor. Daily / Weekly programs and work flows can be created and stored much in advance, for the Trainers
								/ Coaches to take note of.
							</p>

							{/* <ul className="benefits__list flex-between">
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Deliver exceptional experiences.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Reduce wait times & minimize errors.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Let your Customers seamlessly browse your menu and place orders.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>User Real-time Customer Analytics and Reports to increase satisfaction.</p>
								</li>
							</ul> */}
						</div>

						<div className="benefits__image flex-center">
							<img onClick={(e)=>enlargeImg(e)} src="https://img.freepik.com/free-photo/accountant-calculating-profit-with-financial-analysis-graphs_74855-4937.jpg" alt="QR App in hand" data-aos="fade-left" />

							<div className="benefits__arrow benefits__arrow-left">
								<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-vertical.svg" alt="arrow vertical" />
							</div>

							<div className="benefits__arrow benefits__arrow-bottom">
								<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-horizontal.svg" alt="arrow horizontal" />
							</div>
						</div>
					</section>

					<div className="benefits__discover" data-aos="zoom-in">
						<p>Discover</p>
					</div>

					<section className="benefits-tech">
						<div className="benefits__image flex-center">
							<img onClick={(e)=>enlargeImg(e)} src="https://img.freepik.com/free-photo/businesswoman-networking-using-digital-devices_53876-101875.jpg" alt="QR app screenshots" data-aos="fade-right" />

							<div className="benefits__arrow benefits__arrow-top">
								<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-horizontal.svg" alt="arrow horizontal" />
							</div>

							<div className="benefits__arrow benefits__arrow-right">
								<img onClick={(e)=>enlargeImg(e)} src="assets/icons/arrow-vertical.svg" alt="arrow vertical" />
							</div>
						</div>

						<div className="benefits__content" data-aos="fade-left">
							<p className="website__subtitle">Wait, there's more:</p>

							<h3 className="website__title">Manage & Optimize Revenue</h3>
							<p className="website__description">
								The Academy / Club Admin upon receipt of transfer screenshots can Credit the Players / Members Academy Wallet, whereby allowing them to either pay for their Program Fees, or Private Sessions or other
								financial services utilized by them during the course of their membership.
							</p>

							{/* <ul className="benefits__list flex-between">
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Unlock new avenues for revenue generation, all in one place.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>From customization choices with add-ons to offering delivery services.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Endless revenue streams - Take Away, Dine in, Delivery or In Room Dining.</p>
								</li>
								<li>
									<div className="benefits__icon flex-center">
										<img onClick={(e)=>enlargeImg(e)} src="assets/icons/check.svg" alt="check" />
									</div>
									<p>Maximize sales opportunities and drive profitability.</p>
								</li>
							</ul> */}
						</div>
					</section>
				</section>

				<section className="subscription flex-center" id="services">
					<h2 data-aos="flip-down">Get Started Now</h2>
					<p data-aos="fade-up">Automate your Sports Enterprise with EWizPro now.</p>

					<div className="subscription__form" data-aos="fade-up">
						<Link to="academy-register">
							{' '}
							<button>Join Us & Request a Demo</button>
						</Link>
					</div>
				</section>
				<footer className="custom-footer flex-between ">
					<h3 className="footer__logo">
						<span>Ewiz</span>Pro
					</h3>

					<ul className="footer__nav mb-0">
						<li>
							<Link to="academy-register">Join Us</Link>
						</li>

						<p className="mb-0">
							For a demo or any queries, contact: Tavishi Kapoor <br />
							WhatsApp: <a href="https://wa.me/66631391333" target='_blank'>+66 63 139 1333</a><br />
							Email: <a href='mailto:tavishikap@gmail.com'>tavishikap@gmail.com</a>
						</p>
					</ul>
				</footer>
			</div>
		</main>
	);
};

export default Page;
