import React from "react";
import axios from "axios";

const headers = {
  Accept: "*",
  Accept: "application/json",
  "Content-type": "application/json",
  // "Content-type":"multipart/form-data",
  // "Access-Control-Allow-Origin": "*",
};

const token = () => {
  if (localStorage.getItem("userinfo")) {
    let tokenVal = localStorage.getItem("userinfo").replace(/['"]+/g, "");
    return "Bearer " + tokenVal;
  }
};

function joinurl(baseURL, url) {
  return `${baseURL}/${url}`;
}

class Service {
  constructor() {
    this.domain = process.env.REACT_APP_BASE_URL;
  }

  request() {
    return { ...headers, Authorization: token() }
  }

  post(url, data) {
    const method = "POST";
    return axios.post(joinurl(this.domain, url), data, { headers: this.request() }).then((res) => {
      const { data = {} } = res
      return data
    }).catch(error => error)
  }

  get(url, id) {
    const method = "GET";
    if (id) {
      url = `${url}/${id}`;
    }
    return axios.get(joinurl(this.domain, url), { headers: this.request() }).then((res) => {
      const { data = {} } = res
      return data
    }
    );
  }


  delete(url, data) {
    const method = "Delete";
    return axios.delete(joinurl(this.domain, url), data, { headers: this.request() }).then((res) => {
      const { data = {} } = res
      return data
    }).catch(error => error)
  }

  put(url, data) {
    const method = "PUT";
    return axios.put(joinurl(this.domain, url), data, { headers: this.request() }).then((res) => {
      const { data = {} } = res
      return data
    }).catch(error => error)
  }
  getAll() { }
}
export default Service;
