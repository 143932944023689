import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import logosrc from '../../public/images/logo.svg';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import SvgLogo from './SvgLogo';

export default function Footer() {
	return (
		<footer className="border-top pt-5">
			<Container>
				<Row>
					<Col lg={4} md={6}>
						<Link to="/">
							<SvgLogo color1={'#FF5C00'} color2={'#ffffff'} />
						</Link>
						<p className="mt-4">
							In today's fast-paced world, efficiency is key, even in the realm of Sports. EWizPro Sports Club / Academy Automation system aims to revolutionize the way sports organizations manage their operations, making it
							easier for Players, Coaches, and Administrators alike, to focus on what truly matters: The Training / The Game.
						</p>
						<p className="mt-4">
							View / manage your Academy / Club finances from anywhere in the world, at the tip of your fingertips. Keep a tab on the activity of your Coaches, Players, Club Members without physically stepping on your
							premises.
						</p>
					</Col>
					<Col lg={4} md={6} className="pe-md-3"></Col>

					<Col lg={4} md={6}>
						<h4 className="fw-bold">Connect With Us</h4>
						<h6 className="fw-bold mt-4">For a demo or any queries, contact:</h6>
						<p>
							Tavishi Kapoor <br />
							WhatsApp: +66 63 139 1333 <br />
							Email: tavishikap@gmail.com{' '}
						</p>

						{/* <div className="my-4 social-media-icons d-flex align-content-center">
              <a href="#">
                <FaFacebookF />
              </a>
              <a href="#">
                <FaTwitter />
              </a>
              <a href="#">
                <FaLinkedinIn />
              </a>
            </div> */}
					</Col>
				</Row>
			</Container>
			<div className="bottomFooter text-center pb-1 pt-4 mt-5 border-top">Copyright © {new Date().getFullYear()}</div>
		</footer>
	);
}
