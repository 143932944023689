import React from "react";

export default function SvgLogo({
  className,
  color1,
  color2,
  height = 50,
  isLarge,
}) {
  // function adjust(color, amount) {
  //   return (
  //     "#" +
  //     color
  //       .replace(/^#/, "")
  //       .replace(/../g, (color) =>
  //         (
  //           "0" +
  //           Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
  //             16
  //           )
  //         ).substr(-2)
  //       )
  //   );
  // }
  // let colorTwo = adjust(colorOne, -80);
  return (
    <div className={"d-flex align-items-center svgLogo " + className}>
      {!isLarge ? (
        <svg
          height={height}
          width={height * 1.5}
          viewBox="0 0 188 132"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3865 7.73107C9.90741 13.8734 2 24.3243 2 40H18C18 30.5908 22.4042 24.8411 28.0873 21.1585C34.1576 17.225 41.5103 15.8566 45.7325 16.0114C45.8301 16.015 45.9278 16.0168 46.0256 16.0168H88V0.0167732H46.1614C38.9833 -0.21064 28.4122 1.8825 19.3865 7.73107Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.436 75.0924C9.47011 68.7384 2 57.53 2 40H18C18 52.2584 22.8415 58.2883 28.0377 61.6013C33.7864 65.2666 40.9923 66.1917 45.6776 65.9877C45.7935 65.9826 45.9095 65.9801 46.0256 65.9801H88V81.9801H46.1904C39.461 82.2435 28.7644 81.04 19.436 75.0924Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.753 6.25798C167.5 11.2774 174 19.8769 174 32.6857H158C158 26.1434 155.003 22.2452 151.053 19.6854C146.715 16.8748 141.421 15.9005 138.502 16.0076C138.404 16.0112 138.307 16.0129 138.209 16.0129H104V0.0129433H138.076C143.948 -0.16647 152.461 1.53244 159.753 6.25798Z"
            fill={color2}
            className="color2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.704 61.2989C167.937 56.0496 174 46.8113 174 32.6857H158C158 41.5397 154.566 45.5995 151.102 47.8077C147.086 50.3682 141.939 51.0538 138.557 50.9065C138.441 50.9015 138.325 50.899 138.209 50.899H104V66.899H138.046C143.473 67.1055 152.11 66.1403 159.704 61.2989Z"
            fill={color2}
            className="color2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88 31H44V51H88V31Z"
            fill={color1}
          />
          <path
            d="M53 41C53 46.5228 48.5228 51 43 51C37.4772 51 33 46.5228 33 41C33 35.4772 37.4772 31 43 31C48.5228 31 53 35.4772 53 41Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43 32C38.0294 32 34 36.0294 34 41C34 45.9706 38.0294 50 43 50C47.9706 50 52 45.9706 52 41C52 36.0294 47.9706 32 43 32ZM43 51C48.5228 51 53 46.5228 53 41C53 35.4772 48.5228 31 43 31C37.4772 31 33 35.4772 33 41C33 46.5228 37.4772 51 43 51Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M103 82L103 51L121 51L121 82L103 82Z"
            fill={color2}
            className="color2"
          />
          <path
            d="M0.284912 102.792C0.284912 102.247 0.485579 101.803 0.886912 101.459C1.28825 101.086 1.77558 100.9 2.34891 100.9H21.3979C21.3979 102.104 21.1542 102.993 20.6669 103.566C20.1796 104.139 19.4199 104.426 18.3879 104.426H4.32691V114.058H16.3669C16.3669 115.262 16.1232 116.151 15.6359 116.724C15.1486 117.297 14.4032 117.584 13.3999 117.584H4.32691V127.474H19.2909C20.2942 127.474 21.0396 127.761 21.5269 128.334C22.0142 128.907 22.2579 129.796 22.2579 131H2.34891C1.77558 131 1.28825 130.828 0.886912 130.484C0.485579 130.111 0.284912 129.653 0.284912 129.108V102.792Z"
            fill={color1}
          />
          <path
            d="M36.0001 131.387C34.9681 131.387 34.1224 131.143 33.4631 130.656C32.8038 130.14 32.3594 129.423 32.1301 128.506L25.0351 100.9C25.4651 100.642 26.0098 100.513 26.6691 100.513C27.5004 100.513 28.2028 100.728 28.7761 101.158C29.3494 101.559 29.7221 102.118 29.8941 102.835L34.5811 123.217L33.8931 125.754L34.1511 125.84L41.0741 101.846C41.1888 101.416 41.4038 101.086 41.7191 100.857C42.0631 100.628 42.4644 100.513 42.9231 100.513C43.3531 100.513 43.7401 100.628 44.0841 100.857C44.4281 101.086 44.6574 101.416 44.7721 101.846L50.7491 123.389L50.1471 126.012L50.4051 126.098L55.9521 102.835C56.1241 102.118 56.4824 101.559 57.0271 101.158C57.6004 100.728 58.2884 100.513 59.0911 100.513C59.7504 100.513 60.3238 100.642 60.8111 100.9L53.7161 128.506C53.4868 129.423 53.0281 130.14 52.3401 130.656C51.6808 131.143 50.8351 131.387 49.8031 131.387L42.4501 105.372L42.1921 105.458L42.7511 107.479L36.0001 131.387Z"
            fill={color1}
          />
          <path
            d="M66.6107 103.867C66.6107 102.806 66.9547 101.989 67.6427 101.416C68.3594 100.814 69.3627 100.513 70.6527 100.513V128.033C70.6527 129.094 70.2944 129.925 69.5777 130.527C68.8897 131.1 67.9007 131.387 66.6107 131.387V103.867Z"
            fill={color1}
          />
          <path
            d="M78.3245 131C77.7512 131 77.2925 130.842 76.9485 130.527C76.6332 130.212 76.4755 129.767 76.4755 129.194C76.4755 128.764 76.6045 128.391 76.8625 128.076L97.0295 103.179L96.7715 102.964L95.5675 104.383H80.5605C79.5285 104.383 78.7402 104.096 78.1955 103.523C77.6509 102.921 77.3785 102.047 77.3785 100.9H99.8675C101.1 100.9 101.717 101.502 101.717 102.706C101.717 103.136 101.588 103.509 101.33 103.824L81.2055 128.721L81.5065 128.936L82.6675 127.517H98.7925C99.7959 127.517 100.57 127.818 101.115 128.42C101.659 128.993 101.932 129.853 101.932 131H78.3245Z"
            fill={color1}
          />
          <path
            d="M106.861 102.792C106.861 102.247 107.047 101.803 107.42 101.459C107.821 101.086 108.323 100.9 108.925 100.9H121.481C123.373 100.9 125.05 101.301 126.512 102.104C127.974 102.878 129.106 103.982 129.909 105.415C130.712 106.82 131.113 108.439 131.113 110.274C131.113 112.166 130.712 113.829 129.909 115.262C129.135 116.695 128.017 117.799 126.555 118.573C125.093 119.347 123.402 119.734 121.481 119.734H110.903V128.033C110.903 129.122 110.559 129.954 109.871 130.527C109.183 131.1 108.18 131.387 106.861 131.387V102.792ZM121.481 116.165C123.201 116.165 124.563 115.649 125.566 114.617C126.569 113.556 127.071 112.109 127.071 110.274C127.071 108.497 126.555 107.092 125.523 106.06C124.52 104.999 123.172 104.469 121.481 104.469H110.903V116.165H121.481Z"
            fill={color1}
          />
          <path
            d="M136.046 102.792C136.046 102.247 136.232 101.803 136.605 101.459C137.006 101.086 137.508 100.9 138.11 100.9H149.978C152.758 100.9 154.966 101.717 156.6 103.351C158.262 104.956 159.094 107.106 159.094 109.801C159.094 113.184 157.617 115.577 154.665 116.982V117.111C155.955 117.656 156.944 118.487 157.632 119.605C158.32 120.694 158.664 122.027 158.664 123.604V128.033C158.664 129.122 158.32 129.954 157.632 130.527C156.944 131.1 155.94 131.387 154.622 131.387V123.862C154.622 122.171 154.235 120.895 153.461 120.035C152.715 119.146 151.597 118.702 150.107 118.702H140.045V128.033C140.045 129.122 139.701 129.954 139.013 130.527C138.353 131.1 137.364 131.387 136.046 131.387V102.792ZM149.849 115.219C151.425 115.219 152.672 114.732 153.59 113.757C154.536 112.782 155.009 111.449 155.009 109.758C155.009 108.038 154.564 106.719 153.676 105.802C152.787 104.885 151.511 104.426 149.849 104.426H140.045V115.219H149.849Z"
            fill={color1}
          />
          <path
            d="M175.363 131.387C172.984 131.387 170.848 130.928 168.956 130.011C167.093 129.065 165.631 127.775 164.57 126.141C163.51 124.478 162.979 122.644 162.979 120.637V111.177C162.979 109.17 163.51 107.364 164.57 105.759C165.631 104.125 167.093 102.849 168.956 101.932C170.848 100.986 172.984 100.513 175.363 100.513C177.771 100.513 179.907 100.986 181.77 101.932C183.662 102.849 185.139 104.125 186.199 105.759C187.26 107.364 187.79 109.17 187.79 111.177V120.637C187.79 122.644 187.26 124.478 186.199 126.141C185.139 127.775 183.662 129.065 181.77 130.011C179.878 130.928 177.743 131.387 175.363 131.387ZM175.363 127.861C178.029 127.861 180.108 127.202 181.598 125.883C183.089 124.564 183.834 122.801 183.834 120.594V111.263C183.834 109.056 183.089 107.307 181.598 106.017C180.108 104.698 178.029 104.039 175.363 104.039C172.726 104.039 170.662 104.698 169.171 106.017C167.681 107.307 166.935 109.056 166.935 111.263V120.594C166.935 122.801 167.681 124.564 169.171 125.883C170.662 127.202 172.726 127.861 175.363 127.861Z"
            fill={color1}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={height}
          // width={height * 10}
          viewBox="0 0 529 83"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3865 7.73065C7.90741 13.873 0 24.3239 0 39.9996H16C16 30.5904 20.4042 24.8407 26.0873 21.1581C32.1576 17.2246 39.5103 15.8562 43.7325 16.011C43.8301 16.0146 43.9278 16.0164 44.0256 16.0164H86V0.0163574H44.1614C36.9833 -0.211056 26.4122 1.88209 17.3865 7.73065Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.436 75.092C7.47011 68.738 0 57.5295 0 39.9996H16C16 52.258 20.8415 58.2878 26.0377 61.6009C31.7864 65.2661 38.9923 66.1913 43.6776 65.9873C43.7935 65.9822 43.9095 65.9797 44.0256 65.9797H86V81.9797H44.1904C37.461 82.243 26.7644 81.0396 17.436 75.092Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M157.753 6.25755C165.5 11.277 172 19.8764 172 32.6853H156C156 26.143 153.003 22.2447 149.053 19.685C144.715 16.8743 139.421 15.9001 136.502 16.0071C136.404 16.0107 136.307 16.0125 136.209 16.0125H102V0.0125198H136.076C141.948 -0.166893 150.461 1.53202 157.753 6.25755Z"
            fill="#202020"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M157.704 61.2984C165.937 56.0492 172 46.8109 172 32.6853H156C156 41.5393 152.566 45.5991 149.102 47.8073C145.086 50.3678 139.939 51.0534 136.557 50.9061C136.441 50.9011 136.325 50.8985 136.209 50.8985H102V66.8985H136.046C141.473 67.105 150.11 66.1399 157.704 61.2984Z"
            fill="#202020"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86 30.9995H42V50.9995H86V30.9995Z"
            fill={color1}
          />
          <path
            d="M51 40.9995C51 46.5224 46.5228 50.9995 41 50.9995C35.4772 50.9995 31 46.5224 31 40.9995C31 35.4767 35.4772 30.9995 41 30.9995C46.5228 30.9995 51 35.4767 51 40.9995Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 31.9995C36.0294 31.9995 32 36.029 32 40.9995C32 45.9701 36.0294 49.9995 41 49.9995C45.9706 49.9995 50 45.9701 50 40.9995C50 36.029 45.9706 31.9995 41 31.9995ZM41 50.9995C46.5228 50.9995 51 46.5224 51 40.9995C51 35.4767 46.5228 30.9995 41 30.9995C35.4772 30.9995 31 35.4767 31 40.9995C31 46.5224 35.4772 50.9995 41 50.9995Z"
            fill={color1}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101 81.9996L101 50.9996L119 50.9995L119 81.9995L101 81.9996Z"
            fill="#202020"
          />
          <path
            d="M183 5.97911C183 4.55014 183.525 3.38441 184.576 2.4819C185.627 1.50418 186.903 1.01532 188.405 1.01532H238.286C238.286 4.17409 237.648 6.50557 236.372 8.00975C235.096 9.51393 233.107 10.266 230.404 10.266H193.584V35.5362H225.112C225.112 38.695 224.474 41.0265 223.198 42.5306C221.922 44.0348 219.97 44.7869 217.343 44.7869H193.584V70.734H232.769C235.396 70.734 237.348 71.4861 238.624 72.9902C239.9 74.4944 240.538 76.8259 240.538 79.9847H188.405C186.903 79.9847 185.627 79.5334 184.576 78.6309C183.525 77.6532 183 76.4499 183 75.0209V5.97911Z"
            fill={color2}
          />
          <path
            d="M276.523 81C273.821 81 271.607 80.3607 269.88 79.0822C268.154 77.7284 266.99 75.8482 266.389 73.4415L247.811 1.01532C248.937 0.33844 250.363 0 252.089 0C254.266 0 256.105 0.56407 257.607 1.69221C259.108 2.74513 260.084 4.2117 260.534 6.09193L272.808 59.5655L271.006 66.2214L271.682 66.4471L289.81 3.49722C290.11 2.36908 290.673 1.50418 291.499 0.902509C292.4 0.300837 293.451 0 294.652 0C295.778 0 296.791 0.300837 297.692 0.902509C298.593 1.50418 299.193 2.36908 299.494 3.49722L315.145 60.0167L313.569 66.8983L314.244 67.124L328.77 6.09193C329.22 4.2117 330.158 2.74513 331.585 1.69221C333.086 0.56407 334.887 0 336.989 0C338.716 0 340.217 0.33844 341.493 1.01532L322.914 73.4415C322.314 75.8482 321.113 77.7284 319.311 79.0822C317.585 80.3607 315.37 81 312.668 81L293.413 12.7479L292.738 12.9735L294.202 18.2758L276.523 81Z"
            fill={color2}
          />
          <path
            d="M356.68 8.79944C356.68 6.01671 357.581 3.87326 359.382 2.36909C361.259 0.789696 363.886 0 367.264 0V72.2006C367.264 74.9833 366.326 77.1643 364.449 78.7437C362.648 80.2479 360.058 81 356.68 81V8.79944Z"
            fill={color2}
          />
          <path
            d="M387.354 79.9847C385.852 79.9847 384.651 79.571 383.751 78.7437C382.925 77.9164 382.512 76.7507 382.512 75.2465C382.512 74.1184 382.85 73.1407 383.525 72.3134L436.334 6.99443L435.659 6.43036L432.506 10.1532H393.209C390.507 10.1532 388.442 9.40111 387.016 7.89693C385.59 6.31755 384.877 4.02368 384.877 1.01532H443.766C446.994 1.01532 448.608 2.59471 448.608 5.75348C448.608 6.88162 448.27 7.85934 447.594 8.68663L394.898 74.0056L395.686 74.5696L398.726 70.8468H440.951C443.578 70.8468 445.605 71.6365 447.031 73.2159C448.458 74.7201 449.171 76.9763 449.171 79.9847H387.354Z"
            fill={color1}
          />
          <path
            d="M462.079 5.97911C462.079 4.55014 462.567 3.38441 463.543 2.4819C464.594 1.50418 465.908 1.01532 467.484 1.01532H500.363C505.317 1.01532 509.709 2.06825 513.537 4.1741C517.365 6.20474 520.331 9.10028 522.432 12.8607C524.534 16.546 525.585 20.7953 525.585 25.6086C525.585 30.5724 524.534 34.9345 522.432 38.695C520.406 42.4554 517.478 45.351 513.65 47.3816C509.821 49.4122 505.392 50.4276 500.363 50.4276H472.664V72.2006C472.664 75.0585 471.763 77.2396 469.961 78.7437C468.16 80.2479 465.532 81 462.079 81V5.97911ZM500.363 41.0641C504.867 41.0641 508.433 39.7103 511.06 37.0028C513.687 34.2201 515.001 30.422 515.001 25.6086C515.001 20.9457 513.65 17.2604 510.947 14.5529C508.32 11.7702 504.792 10.3788 500.363 10.3788H472.664V41.0641H500.363Z"
            fill={color1}
          />
          <path
            d="M538.502 5.97911C538.502 4.55014 538.99 3.38441 539.965 2.4819C541.016 1.50418 542.33 1.01532 543.906 1.01532H574.984C582.265 1.01532 588.045 3.15878 592.324 7.44569C596.678 11.6574 598.855 17.2981 598.855 24.3677C598.855 33.2423 594.989 39.5223 587.257 43.2075V43.546C590.635 44.9749 593.225 47.156 595.027 50.0891C596.828 52.9471 597.729 56.4443 597.729 60.5808V72.2006C597.729 75.0585 596.828 77.2396 595.027 78.7437C593.225 80.2479 590.598 81 587.145 81V61.2577C587.145 56.8203 586.131 53.4735 584.104 51.2173C582.153 48.8858 579.225 47.7201 575.322 47.7201H548.973V72.2006C548.973 75.0585 548.073 77.2396 546.271 78.7437C544.545 80.2479 541.955 81 538.502 81V5.97911ZM574.646 38.5822C578.775 38.5822 582.04 37.3036 584.442 34.7465C586.919 32.1894 588.158 28.6922 588.158 24.2549C588.158 19.7423 586.994 16.2827 584.667 13.876C582.34 11.4694 579 10.266 574.646 10.266H548.973V38.5822H574.646Z"
            fill={color1}
          />
          <path
            d="M641.459 81C635.228 81 629.636 79.7967 624.682 77.39C619.802 74.9081 615.974 71.5237 613.196 67.2368C610.419 62.8746 609.03 58.0613 609.03 52.7967V27.9777C609.03 22.7131 610.419 17.9749 613.196 13.7632C615.974 9.47632 619.802 6.12953 624.682 3.72284C629.636 1.24095 635.228 0 641.459 0C647.764 0 653.357 1.24095 658.236 3.72284C663.19 6.12953 667.056 9.47632 669.834 13.7632C672.611 17.9749 674 22.7131 674 27.9777V52.7967C674 58.0613 672.611 62.8746 669.834 67.2368C667.056 71.5237 663.19 74.9081 658.236 77.39C653.282 79.7967 647.689 81 641.459 81ZM641.459 71.7493C648.44 71.7493 653.882 70.0195 657.786 66.5599C661.689 63.1003 663.641 58.4749 663.641 52.6838V28.2033C663.641 22.4123 661.689 17.8245 657.786 14.4401C653.882 10.9805 648.44 9.2507 641.459 9.2507C634.553 9.2507 629.148 10.9805 625.245 14.4401C621.341 17.8245 619.389 22.4123 619.389 28.2033V52.6838C619.389 58.4749 621.341 63.1003 625.245 66.5599C629.148 70.0195 634.553 71.7493 641.459 71.7493Z"
            fill={color1}
          />
        </svg>
      )}
    </div>
  );
}
